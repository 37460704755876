@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.container {
	@include box-shadow-hover-transition();
	@include basic-flex();
	width: 200px;
	height: 200px;
	@media screen and (max-width: $extraLargeScreen) {
		width: 180px;
		height: 180px;
	}
	@media screen and (max-width: $maxPhone) {
		width: 40%;
	}
	@media screen and (max-width: $maxSmallPhone) {
		width: 80vw;
	}
	justify-content: center;
	border-radius: 15px;
	background: $backgroundGrey;
	margin: 0 10px 40px;
	background-size: cover;
	&:hover .label {
		@include box-shadow-hover-transition();
		margin-top: -1.25rem;
	}
}

.label {
	@include box-shadow();
	align-self: start;
	text-align: center;
	font-weight: 600;
	color: $backgroundWhite;
	background: $brand-navy;
	padding: 5px;
	border-radius: 5px;
	margin-top: -1rem;
	transition: 0.3s;
}
