@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.container {
	display: flex;
	flex-direction: column;
	padding: 4rem 10vw;
	@media screen and (max-width: $maxPhone) {
		padding: 10vh 3vw;
	}
}

.links {
	@include basic-flex();
	align-items: stretch;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 70vw;
	margin-top: 10vh;
	align-self: center;
	@media screen and (max-width: $maxTablet) {
		width: 80vw;
		justify-content: center;
	}
}

.link {
	@include basic-flex();
	@include box-shadow-hover-transition();
	cursor: pointer;
	overflow: hidden;
	margin-bottom: 2rem;
	font-size: 1.25rem;
	background: $backgroundGrey;
	border-radius: 1rem;
	width: 45%;
	max-width: 500px;
	@media screen and (max-width: $maxTablet) {
		width: 100%;
	}
	@media screen and (max-width: $maxPhone) {
		font-size: 0.9rem;
	}
	&:hover {
		margin-top: -0.5rem;
		margin-bottom: 2.5rem;
		background: $brand-navy;
		color: #fff;
		.image {
			background: $brand-yellow;
			color: $brand-navy;
		}
	}
}

.icon {
	@include center-child();
	border-radius: 50%;
	padding: 0.75rem;
	margin: 0.5rem 1rem 0.5rem 0.5rem;
	background: $brand-yellow;
	color: $brand-navy;
	svg {
		font-size: 3rem;
	}
}

.info {
	@include basic-flex(column);
	align-items: flex-start;
	justify-content: center;
	height: 100%;
	h3 {
		margin: 0;
	}
	span {
		margin-top: -0.5rem;
		font-size: 1rem;
	}
}
