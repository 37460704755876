@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.container {
	@include basic-flex();
}

.button {
	@include basic-flex();
	justify-content: center;
	border-radius: 50%;
	padding: 8px;
	background-image: radial-gradient($backgroundWhite, $backgroundWhite, $backgroundGrey);
	box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
	color: rgba($color: #000000, $alpha: 0.4);
	&:hover {
		background: $brandYellow;
		color: $brandNavy;
	}
}

.left {
	@extend .button;
	padding-right: 10px;
	padding-left: 6px;
	@media screen and (max-width: $maxPhone) {
		margin-right: -40px;
		z-index: 2;
	}
}

.right {
	@extend .button;
	padding-left: 10px;
	padding-right: 6px;
	@media screen and (max-width: $maxPhone) {
		margin-left: -40px;
	}
}

.content {
	@include basic-flex();
	justify-content: center;
	background: $backgroundGrey;
	width: 80%;
	height: 50vh;
	margin: 0 3vw;
	border-radius: 25px;
	@include header-text(16px);
	@include box-shadow-hover-transition();
	@media screen and (max-width: $maxTablet) {
		width: 100%;
		height: 30vh;
	}
}

.featured {
	position: absolute;
	align-self: flex-start;
	background: $brandYellow;
	padding: 1vh 2vh;
	border-radius: 15px;
	margin-top: -16px;
	@include box-shadow();
}

.info {
	position: absolute;
	align-self: flex-end;
	background: $brandNavy;
	color: $backgroundWhite;
	padding: 1vh 2vh;
	border-radius: 15px;
	margin-bottom: -16px;
	@include box-shadow();
}
