@import '../modules/breakpoints';

.container {
	margin: 15vh 10px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	@media screen and (max-width: $maxLandscape) {
		justify-content: center;
	}
}
