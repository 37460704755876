@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.container {
	@include box-shadow-hover-transition();
	@include basic-flex();
	height: 18rem;
	width: 100%;
	max-width: 60rem;
	border-radius: 1rem;
	background: $bg-grey;
	background-size: cover;
	background-position: center;
	align-items: flex-start;
	justify-content: center;
	margin: 0 auto;
	span {
		@include box-shadow();
		position: absolute;
		background-color: $brand-navy;
		padding: 0.25rem 0.5rem;
		border-radius: 0.5rem;
		color: #fff;
		font-weight: 500;
		margin-top: -1rem;
	}
}
